import { Box, Heading, Paragraph } from "grommet";
import React from "react";
import SiteLayout from "../layout";
export default function NotFound() {
  return (
    <SiteLayout>
      <Box fill justify="center" align="center">
        <Paragraph level="3" color="light-6">
          500
        </Paragraph>
        <Heading
          level="1"
          color="status-critical"
          textAlign="center"
        >
          Something went wrong
          <small>This url is no longer valid!</small>
        </Heading>
      </Box>
    </SiteLayout>
  );
}
